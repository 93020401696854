import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
    'en': {
        lang: 'EN',
        dashboard: 'Dashboard',
        addNewStaff: 'Add New Staff',
        menu: {
            staffInfo: 'Staff Information',
            accountSettings: 'Account Settings',
            report: 'Staff Reports',
            caseReport: 'Case Reports',
            caseManagement: 'Case Management',
            legalCases: 'Legal Cases',
            criminalCases: 'Criminal Cases',
            civilCases: 'Civil Cases',
            defendantCases: 'Defendant Case',
            oathCase: 'Oath Case',
            positionCreation: 'Position Creation',
            medalCreation: 'Medal Creation',
            crimeTypeCreation: 'Crime Type Creation',
            admin: 'Admin',
            systemVariable: 'System Variables',
            caseLetterType: 'Case Letter Types',
            caseTaskType: 'Case Task Types',
            evidenceType: 'Evidence Types',
            decisionTypeCreation: 'Decision Type Creation',
            lawCreation: 'Law Name Creation',
            caseFromPolice: 'Cases From Police'
        },
        caption: {
            accountInfo: 'Account Information',
            changePass: 'Change Password',
            userManagement: 'User Management',
            personalInfo: 'Personal Information',
            initialInfo: 'Basic Information',
            basicInfo: 'Basic Information',
            workingInfo: 'Job Information',
            workBackground: 'Work Background',
            addOathCase: 'New Oath Case',
            addCriminalCase: 'New Criminal Case',
            transferCase: 'Transfer Case from Police',
            addPoliceCase: 'New Case From Police',
            education: 'Education',
            educationLevel: 'Education Level',
            training: 'Training',
            relatives: 'Relatives',
            positionCreation: 'Available Positions',
            editPosition: 'Edit Position',
            addPosition: 'Add Position',
            politicalService: 'Political Service',
            socialMedia: 'Social Network Account',
            technicalSkill: 'Technical Skills',
            languageSkill: 'Language Skills',
            computerSkill: 'Computer Skills',
            medalForm: 'Medal Form',
            systemManagement: 'System Management',
            honorableMedals: 'Medals',
            medalList: 'Medal List',
            editMedal: 'Edit Medal',
            activityHistory: 'Activity History',
            newLetterType: 'New Letter Type',
            editLetterType: 'Edit Letter Type',
            newTaskType: 'New Task Type',
            editTaskType: 'Edit Task Type',
            newEvidenceType: 'New Evidence Type',
            newLaw: 'New Law Name',
            editLaw: 'Edit Law Name',
            editEvidenceType: 'Edit Evidence Type',
            newCrimeType: 'New Crime Type',
            editCrimeType: 'Edit Crime Type',
            newDecisionType: 'New Decision Type',
            editDecisionType: 'Edit Decision Type'
        },
        text: {
            name: 'Name',
            nameLatin: 'Name in Latin',
            staffNameEn: 'Full Name (EN)',
            staffNameKh: 'Full Name (KH)',
            nameEn: 'Name in English',
            nameKh: 'Name in Khmer',
            occupation: 'Occupation',
            gender: 'Gender',
            dob: 'DOB',
            position: 'Position',
            male: 'Male',
            female: 'Female',
            dateOfBirth: 'Date of birth',
            maritalStatus: 'Marital Status',
            currentAddress: 'Current address',
            placeOfBirth: 'Place of birth',
            startDate: 'Start Date',
            startWorkingDate: 'Start working date',
            endDate: 'End Date',
            startDateToEndDate: 'Start date to End date',
            requestDate: 'Requesting Date',
            letterDate: 'Date of Issued Letter',
            alive: 'Alive',
            dead: 'Dead',
            fullname: 'Full name',
            phoneNumber: 'Phone number',
            age: 'Age',
            telegram: 'Telegram number',
            idNumber: 'ID/Nomination Number',
            staffIdNumber: 'Staff ID Number',
            nationalCard: 'National ID',
            nationalIDIssuedDate: 'Issuing Date',
            nationalIDExpiryDate: 'Expiry Date',
            trainingProgramTitle: 'Training program title',
            trainingVenue: 'Training Institution',
            certificateName: 'Name of certificate',
            city: 'City',
            country: 'Country',
            to: 'To',
            from: 'From',
            degreeTitle: 'Qualification title',
            universityName: 'University or Institution name',
            provinceOrState: 'City/Province/State',
            informationNotAvailable: 'The information is not available',
            department: 'Department',
            appointmentNo: 'Appointment No',
            appointmentDate: 'Appointment Date',
            relation: 'Relationship',
            officeName: 'Institution Name',
            address: 'Address',
            male: 'Male',
            female: 'Female',
            at: 'At',
            positionNameEn: 'Position name (EN)',
            positionNameKh: 'Position name (KH)',
            medalNameEn: 'Medal name (EN)',
            medalNameKh: 'Medal name (KH)',
            partyName: "Party's Name",
            place: "Place",
            workingPlace: 'Working Address',
            role: 'Role',
            medalTitle: 'Name / Title of Medal',
            medalProgramTitle: 'Program name and venue',
            giverName: 'Giver',
            receivingDate: 'Receiving Date',
            letterNo: 'Letter number',
            letterTypeNameKh: 'Letter type name (KH)',
            letterTypeNameEn: 'Letter type name (EN)',
            lawNameKh: 'Law Name (KH)',
            lawNameEn: 'Law Name (EN)',
            crimeTypeNameKh: 'Crime type name (KH)',
            crimeTypeNameEn: 'Crime type name (EN)',
            letterTypeCode: 'Letter type code',
            taskTypeKh: 'Task type name (KH)',
            taskTypeEn: 'Task type name (EN)'

        },
        button: {
            save: 'Save',
            cancel: 'Cancel',
            delete: 'Delete',
            newStaff: 'New Staff',
            viewMore: 'View More',
            reset: 'Reset',
            browsePicture: 'Select photo',
            addChild: 'Add child',
            medal: 'Medal',
            newLetterType: 'New Letter Type',
            newEvidenceType: 'New evidence type',
            newLaw: 'New Law Name',
            newCrimeType: 'New crime type',
            newCase: 'New Case',
            regisCase: 'Register Case',
            approve: 'Approve',
            newDecisionType: 'New decision type',
            progress: 'Progress',
            lawyerInfo: 'Lawyer Information'

        },
        select: {
            degreeLevel: 'Select degree level',
            position: 'Select position',
            trainingType: 'Select training type',
            department: 'Select institution or department',
            province: 'Select province',
            court: 'Select court',
            relation: 'Select relation',
            appointmentType: 'Select appointment type',
            selectLanguage: 'Select language',
            selectSkill: 'Select skill',
            reading: 'Reading',
            writing: 'Writing',
            speaking: 'Speaking',
            listening: 'Listening',
            grade: 'Grade',
            type: 'Select type',
            medal: 'Select medal title',
            medalLetterType: 'Type of letter',
            letterType: 'Select letter type'

        },
        case: {
            caseNo: 'Case No',
            reportNo: 'Report No',
            registerDate: 'Registration Date',
            receivingDateFromPolice: 'Complaint Receipt Date',
            describeEvidence: 'Describe Evidence',
            caseDate: 'Case Date',
            courtName: 'Court Name',
            facts: 'Facts',
            plaintiffName: 'Plaintiff Name',
            defendantName: 'Defendant Name',
            relatedPersonName: 'Related Person Name',
            addPlaintiff: 'Add New Plaintiff',
            addDefendant: 'Add New Defendant',
            addRelatedPerson: 'Add Related Person',
            addEvidence: 'Add Evidence',
            addDecision: 'Add Prosecution Decision',
            charge: 'Charge',
            levelOfCrime: 'Level of crime',
            relatedArticle: 'Related Articles',
            caseProgress: 'Case Progress',
            initialInfo: 'Initial Info of Case',
            casePhase: 'Case Phase',
            prosPhase: 'Prosecution Phase',
            caseInfo: 'Case Information',
            policeCaseInfo: 'Police Case Information',
            options: {
                actualOffense: 'Actual Offenses',
                unrealOffense: 'Unreal Offenses'
            },
            evidenceList: 'Evidence Items',
        },
        message: {
            addedPosition: 'Position has been created',
            updatedPosition: 'Position has been updated',
            attachNominationLetter: 'Attach nomination letter',
            addedCaseLetterType: 'Case letter type has been created',
            updatedCaseLetterType: 'Case letter type has been updated',
            addedLawName: 'New law name has been created',
            updatedLawName: 'Law name has been updated',
            addedData: 'Data has been created'
        },

        KDL: 'Kandal',
        KEP: 'Kep',
        OMC: 'Otdar Meanchey',
        PHN: 'Phnom Penh',
        BTM: 'Banteay Meanchey',
        BTB: 'Battambang',
        KCM: 'Kampong Cham',
        KCN: 'Kampong Chhnang',
        KSP: 'Kampong Speu',
        KTM: 'Kampong Thom',
        KPT: 'Kampot',
        KOK: 'Koh Kong',
        KTE: 'Kratie',
        MKR: 'Mondul Kiri',
        PVH: 'Preah Vihear',
        PVG: 'Prey Veng',
        PST: 'Pursat',
        RKR: 'Ratanak Kiri',
        SRP: 'Siemreap',
        PSN: 'Preah Sihanouk',
        STR: 'Stung Treng',
        SVG: 'Svay Rieng',
        TKE: 'Takeo',
        PLN: 'Pailin'

    },
    'kh': {
        lang: 'KH',
        dashboard: 'ទំព័រដើម',
        addNewStaff: 'បន្ថែមបុគ្គលិកថ្មី',
        menu: {
            staffInfo: 'ព័ត៌មានបុគ្គលិក',
            accountSettings: 'សុវត្ថិភាពគណនី',
            report: 'របាយការណ៍បុគ្គលិក',
            caseReport: 'របាយការណ៍សំណុំរឿង',
            caseManagement: 'គ្រប់គ្រងសំណុំរឿង',
            legalCases: 'សំណុំរឿង',
            criminalCases: 'រឿងព្រហ្មទណ្ឌ',
            civilCases: 'រឿងរដ្ឋប្បវេណី',
            defendantCases: 'រឿងក្តីរក្សាការពារ',
            oathCase: 'ករណីសច្ចាប្រណិធាន',
            positionCreation: 'បង្កើតតួនាទី',
            medalCreation: 'បង្កើតមេដាយ',
            crimeTypeCreation: 'បង្កើតប្រភេទបទល្មើស',
            admin: 'ការគ្រប់គ្រង',
            systemVariable: 'គ្រប់គ្រងអថេរប្រព័ន្ធ',
            caseLetterType: 'ប្រភេទលិខិតក្នុងរឿងក្តី',
            caseTaskType: 'ប្រភេទកិច្ចការបានអនុវត្ត',
            evidenceType: 'ប្រភេទភស្តុតាង',
            decisionTypeCreation: 'បង្កើតប្រភេទនៃការសម្រេច',
            lawCreation: 'បង្កើតឈ្មោះច្បាប់',
            caseFromPolice: 'សំណុំរឿងពីនគរបាលយុត្តិធម៌'
        },
        caption: {
            accountInfo: 'ព​ត៌​មាន​គណនី',
            changePass: 'ផ្លាស់ប្តូរពាក្យសម្ងាត់',
            userManagement: 'ការគ្រប់គ្រងអ្នកប្រើប្រាស់',
            personalInfo: 'ព័ត៌មាន​ផ្ទាល់ខ្លួន',
            basicInfo: 'ព័ត៌មាន​មូលដ្ឋាន',
            workingInfo: 'ព័ត៌មាន​ការងារ',
            initialInfo: 'ព័ត៌មានបុគ្គលិក',
            workBackground: 'ប្រវត្តិការងារ',
            addOathCase: 'បញ្ចូលករណីសច្ចាប្រណិធាន',
            addCriminalCase: 'ចុះសំណុំរឿងព្រហ្មទណ្ឌថ្មី',
            transferCase: 'ផ្ទេរសំណុំរឿងពីនគរបាលយុតិធម៍',
            addPoliceCase: 'ចុះសំណុំរឿងក្នុងដំណាក់កាលនគរបាលយុតិធម៍',
            education: '​ការ​អប់រំ',
            educationLevel: 'កម្រិត​ការ​អប់រំ',
            training: 'ការបណ្តុះបណ្តាល',
            relatives: 'ព័ត៌មានគ្រួសារ',
            positionCreation: 'បញ្ជីតួនាទីក្នុងប្រព័ន្ធ',
            editPosition: 'កែតួនាទី',
            addPosition: 'បង្កើតតួនាទី',
            politicalService: 'កិច្ចការនយោបាយ',
            socialMedia: 'ទំនាក់ទំនងបណ្តាញសង្គម',
            technicalSkill: 'ជំនាញបច្ចេកទេស',
            languageSkill: 'ការប្រើប្រាស់ភាសារបរទេស',
            computerSkill: 'ការប្រើប្រាស់កម្មវិធីកុំព្យូទ័រ',
            medalForm: 'មេដាយកិត្តិយស',
            systemManagement: 'ការគ្រប់គ្រងប្រព័ន្ធ',
            honorableMedals: 'គ្រឿងឥស្សរិយយស',
            medalList: 'តារាងមេដាយ',
            editMedal: 'កែមេដាយ',
            activityHistory: 'ប្រវត្តិសកម្មភាព',
            newLetterType: 'បង្កើតប្រភេទលិខិតថ្មី',
            editLetterType: 'កែប្រភេទលិខិត',
            newTaskType: 'បង្កើតប្រភេទកិច្ចការថ្មី',
            editTaskType: 'កែប្រភេទកិច្ចការ',
            newEvidenceType: 'បង្កើតប្រភេទភស្តុតាងថ្មី',
            newLaw: 'បង្កើតឈ្មោះច្បាប់ថ្មី',
            editEvidenceType: 'កែប្រែប្រភេទភស្តុតាងថ្មី',
            editLaw: 'កែប្រែឈ្មោះច្បាប់',
            newCrimeType: 'បង្កើតប្រភេទបទល្មើសថ្មី',
            editCrimeType: 'កែប្រែប្រភេទបទល្មើស',
            newDecisionType: 'បង្កើតប្រភេទសេចក្តីសម្រេចថ្មី',
            editDecisionType: 'កែប្រែប្រភេទសេចក្តីសម្រេច'
        },
        text: {
            name: 'ឈ្មោះ',
            nameLatin: 'ឈ្មោះជាអក្សរឡាតាំង',
            staffNameEn: 'ឈ្មោះជាអក្សរឡាតាំង',
            staffNameKh: 'ឈ្មោះជាភាសាខ្មែរ',
            nameEn: 'ឈ្មោះជាអក្សរឡាតាំង',
            nameKh: 'ឈ្មោះជាភាសាខ្មែរ',
            occupation: 'មុខរបរ',
            gender: 'ភេទ',
            dob: 'កើត​នៅ​ថ្ងៃទី',
            position: 'មុខតំណែង',
            male: 'ប្រុស',
            female: 'ស្រី',
            dateOfBirth: 'ថ្ងៃខែ​ឆ្នាំ​កំណើត',
            maritalStatus: 'ស្ថានភាពគ្រួសារ',
            currentAddress: 'អាស័យ​ដ្ឋាន​បច្ចុប្បន្ន',
            placeOfBirth: 'ទី​កន្លែង​កំណើត',
            startDate: 'ថ្ងៃខែឆ្នាំចាប់ផ្តើម',
            startWorkingDate: 'ថ្ងៃខែ​ឆ្នាំ​បម្រើការងារ',
            endDate: 'ថ្ងៃខែឆ្នាំបញ្ចប់',
            requestDate: 'កាលបរិច្ឆេទស្នើសុំ',
            startDateToEndDate: 'ថ្ងៃខែឆ្នាំចាប់ផ្តើម ដល់បញ្ចប់',
            letterDate: 'កាលបរិច្ចេទចេញលិខិត',
            alive: 'នៅរស់',
            dead: 'ស្លាប់',
            fullname: 'ឈ្មោះពេញ',
            phoneNumber: 'លេខទូរសព្ទ',
            age: 'អាយុ',
            telegram: 'លេខ Telegram',
            idNumber: 'លេខកាត/លិខិតតែងតាំង',
            staffIdNumber: 'អត្តលេខ',
            nationalCard: 'លេខអត្តសញ្ញាណប័ណ្ណ',
            nationalIDIssuedDate: 'ថ្ងៃខែឆ្នាំផ្តល់ជូន',
            nationalIDExpiryDate: 'ថ្ងៃខែឆ្នាំផុតកំណត់',
            trainingProgramTitle: 'ចំណងជើងការបណ្តុះបណ្តាល',
            trainingVenue: 'ឈ្មោះសាលា ឬស្ថាប័នបណ្តុះបណ្តាល',
            certificateName: 'ឈ្មោះវិញ្ញាបនបត្រ',
            city: 'ទីក្រុង',
            country: 'ប្រទេស',
            to: 'ដល់',
            from: 'ពី',
            degreeTitle: 'ចំណងជើងសញ្ញាបត្រ',
            universityName: 'ឈ្មោះសាលា ឬស្ថាប័នសិក្សា',
            provinceOrState: 'រាជធានី/ខេត្ត/រដ្ឋ',
            informationNotAvailable: 'មិនទាន់មានព័ត៌មាន',
            department: 'នាយកដ្ឋាន',
            appointmentNo: 'លេខលិខិតតែងតាំង',
            appointmentDate: 'កាលបរិច្ឆេទតែងតាំង',
            relation: 'ទំនាក់ទំនង',
            officeName: 'ឈ្មោះស្ថាប័នដែលបានបំពេញការងារ',
            address: 'អាស័យ​ដ្ឋាន​',
            male: 'ប្រុស',
            female: 'ស្រី',
            at: 'នៅ',
            positionNameEn: 'ឈ្មោះតួនាទី (ឡាតាំង)',
            positionNameKh: 'ឈ្មោះតួនាទី (ភាសារខ្មែរ)',
            medalNameEn: 'ឈ្មោះមេដាយ (ឡាតាំង)',
            medalNameKh: 'ឈ្មោះមេដាយ (ភាសារខ្មែរ)',
            partyName: 'ឈ្មោះបក្ស​ អង្គការចាត់តាំង',
            place: 'ទីកន្លែង',
            workingPlace: 'ទីកន្លែងបំពេញការងារ',
            role: 'តួនាទី',
            medalTitle: 'ឈ្មោះ/ចំណងជើងគ្រឿងឥស្សរិយយស',
            medalProgramTitle: 'ឈ្មោះកម្មវិធី និងទីកន្លែងទទួល',
            giverName: 'ប្រគល់ដោយ',
            receivingDate: 'ថ្ងៃខែឆ្នាំទទួល',
            letterNo: 'លេខលិខិត',
            letterTypeNameKh: 'ឈ្មោះប្រភេទលិខិត (ភាសារខ្មែរ)',
            letterTypeNameEn: 'ឈ្មោះប្រភេទលិខិត (ឡាតាំង)',
            letterTypeCode: 'លេខកូដប្រភេទលិខិត',
            lawNameKh: 'ឈ្មោះច្បាប់ជាភាសារខ្មែរ',
            lawNameEn: 'ឈ្មោះច្បាប់ជាឡាតាំង',
            crimeTypeNameKh: 'ឈ្មោះប្រភេទបទល្មើស (ភាសារខ្មែរ)',
            crimeTypeNameEn: 'ឈ្មោះប្រភេទបទល្មើស (ឡាតាំង)',
            taskTypeKh: 'ឈ្មោះប្រភេទកិច្ចការ (ភាសារខ្មែរ)',
            taskTypeEn: 'ឈ្មោះប្រភេទកិច្ចការ (ឡាតាំង)'
        },
        button: {
            save: 'រក្សាទុក',
            cancel: 'បោះបង់',
            delete: 'លុប',
            newStaff: 'បង្កើតថ្មី',
            viewMore: 'មើលលម្អិត',
            reset: 'លុប',
            browsePicture: 'ជ្រើសរើសរូបភាព',
            addChild: 'បញ្ចូលព័ត៌មានកូន',
            medal: 'មេដាយ',
            newLetterType: 'ប្រភេទលិខិតថ្មី',
            newEvidenceType: 'ប្រភេទភស្តុតាងថ្មី',
            newTaskType: 'ប្រភេទកិច្ចការថ្មី',
            newCrimeType: 'ប្រភេទបទល្មើសថ្មី',
            newCase: 'ចុះសំណុំរឿងថ្មី',
            newLaw: 'ឈ្មោះច្បាប់ថ្មី',
            regisCase: 'ចុះសំណុំរឿង',
            approve: 'យល់ព្រម',
            newDecisionType: 'ប្រភេទសេចក្តីសម្រេចថ្មី',
            progress: 'វឌ្ឍនភាព',
            lawyerInfo: 'ព័ត៌មានមេធាវី'
        },
        select: {
            degreeLevel: 'ជ្រើសរើសកម្រិតសញ្ញាបត្រ',
            position: 'ជ្រើសរើសតួនាទី​',
            trainingType: 'ជ្រើសរើសប្រភេទនៃការបណ្តុះបណ្តាល',
            department: 'ជ្រើសរើសស្ថាប័នបម្រើការងារ',
            province: 'ជ្រើសរើសខេត្ត',
            court: 'ជ្រើសរើសកន្លែងការងារ/តុលាការ',
            relation: 'ទំនាក់ទំនង',
            appointmentType: 'ប្រភេទនៃការតែងតាំង',
            selectLanguage: 'ជ្រើសរើសភាសា',
            selectSkill: 'ជ្រើសរើសជំនាញ',
            reading: 'ការអាន',
            writing: 'ការសរសេរ',
            speaking: 'ការនិយាយ',
            listening: 'ការស្តាប់',
            grade: 'និទ្ទេស',
            type: 'ជ្រើសរើសប្រភេទ',
            medal: 'ឈ្មោះ/ចំណងជើងគ្រឿងឥស្សរិយយស',
            medalLetterType: 'ប្រភេទលិខិតផ្តល់',
            letterType: 'ជ្រើសរើសប្រភេទលិខិត'
        },
        case: {
            caseNo: 'លេខសំណុំរឿង',
            reportNo: 'លេខរបាយការណ៍',
            registerDate: 'កាលបរិច្ឆេទ​ចុះបញ្ជី',
            receivingDateFromPolice: 'ថ្ងៃទទួលពាក្យបណ្តឹង',
            describeEvidence: 'ពិពណ៌នាអំពីភស្តុតាង',
            caseDate: 'កាលបរិច្ឆេទ​រឿងក្តី',
            courtName: 'ឈ្មោះតុលាការ',
            facts: 'អង្គហេតុ',
            plaintiffName: 'ឈ្មោះដើមបណ្តឹង/ជនរងគ្រោះ',
            defendantName: 'ឈ្មោះជនសង្ស័យ',
            relatedPersonName: 'ឈ្មោះជនទាក់ទិន',
            addPlaintiff: 'បញ្ចូលដើមបណ្តឹង/ជនរងគ្រោះ',
            addDefendant: 'បញ្ចូលជនសង្ស័យ',
            addRelatedPerson: 'បញ្ចូលជនទាក់ទិន',
            addEvidence: 'បញ្ចូលភស្តុតាង',
            addDecision: 'បញ្ចូលសេចក្តីសម្រេច',
            charge: 'បទចោទប្រកាន់បឋម',
            levelOfCrime: 'កម្រិទបទល្មើស',
            relatedArticle: 'មាត្រាពាក់ព័ន្ធ',
            caseProgress: 'ដំណើរការសំណុំរឿង',
            initialInfo: 'ព័ត៌មានបឋមនៃសំណុំរឿង',
            casePhase: 'ដំណាក់កាលនៃសំណុំរឿង',
            prosPhase: 'ដំណាក់កាលអយ្យការ',
            caseInfo: 'ព័ត៌មានសំណុំរឿង',
            policeCaseInfo: 'ព័ត៌មានសំណុំរឿងនគរបាលយុត្តិធម៌',
            options: {
                actualOffense: 'បទល្មើសជាក់ស្តែង',
                unrealOffense: 'បទល្មើសមិនជាក់ស្តែង'
            },
            evidenceList: 'តារាងភស្តុតាង'

        },
        message: {
            addedPosition: 'តួនាទីត្រូវបានបង្កើត',
            updatedPosition: 'តួនាទីត្រូវបានកែតម្រូវ',
            attachNominationLetter: 'ភ្ជាប់លិខិតតែងតាំង',
            addedCaseLetterType: 'ប្រភេទលិខិតត្រូវបានបង្កើត',
            updatedCaseLetterType: 'ប្រភេទលិខិតត្រូវបានកែតម្រូវ',
            addedLawName: 'ឈ្មោះច្បាប់ថ្មីត្រូវបានបង្កើត',
            updatedLawName: 'ឈ្មោះច្បាប់ត្រូវបានកែតម្រូវ',
            addedData: 'ទិន្នន័យត្រូវបានបញ្ចូល'
        },
        KDL: 'កណ្តាល',
        KEP: 'កែប',
        OMC: 'ឧត្តរមានជ័យ',
        PHN: 'ភ្នំពេញ',
        BTM: 'បន្ទាយមានជ័យ',
        BTB: 'បាត់ដំបង',
        KCM: 'កំពង់ចាម',
        KCN: 'កំពង់ឆ្នាំង',
        KSP: 'កំពង់ស្ពឺ',
        KTM: 'កំពង់ធំ',
        KPT: 'កំពត',
        KOK: 'កោះកុង',
        KTE: 'ក្រចេះ',
        MKR: 'មណ្ឌលគិរី',
        PVH: 'ព្រះវិហារ',
        PVG: 'ព្រៃវែង',
        PST: 'ពោធិសាត់',
        RKR: 'រតនគិរី',
        SRP: 'សៀមរាប',
        PSN: 'ព្រះសីហនុ',
        STR: 'ស្ទឹងត្រែង',
        SVG: 'ស្វាយរៀង',
        TKE: 'តាកែវ',
        PLN: 'ប៉ៃលិន',

    }
};

export default new VueI18n({
    locale: 'kh',
    fallbackLocale: 'en',
    messages
});
